/* ******************************************************
   Main Sections POAP App
   ****************************************************** */
  
  .loading {
    background: linear-gradient(90deg, #e6e6e6 25%, #fff 37%, #e6e6e6 63%);
    border-radius: 1rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    background-size: 400% 100%;
    animation: loading 3s ease infinite;
  }
  @keyframes loading {
    0% {
      background-position: 100% 50%;
    }
    to {
      background-position: 0 50%;
    }
  }

.poap-app {
  background-color: $bg-color;

  .spinner {
    margin: 100px auto;
    grid-column: 1 / 3;
    position: relative;
  }
  
  @-webkit-keyframes sk-cubemove {
    25% { -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5) }
    50% { -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg) }
    75% { -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5) }
    100% { -webkit-transform: rotate(-360deg) }
  }
  
  @keyframes sk-cubemove {
    25% { 
      transform: translateX(42px) rotate(-90deg) scale(0.5);
      -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
    } 50% { 
      transform: translateX(42px) translateY(42px) rotate(-179deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
    } 50.1% { 
      transform: translateX(42px) translateY(42px) rotate(-180deg);
      -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
    } 75% { 
      transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
      -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    } 100% { 
      transform: rotate(-360deg);
      -webkit-transform: rotate(-360deg);
    }
  }

  &.events-page {
    .header-events {
      padding: 0;
      @media (min-width: $bp-mw) {
        padding: 0 0 100px;
      }
    }
    main {
      padding: 0;
    }
    footer {
      margin: 0;
    }
  }

  .header-events {
    clear: both;
    h1 {
      margin: 0 0 10px;
    }
    p {
      margin-bottom: 20px;  
    }
  }
}

.home-main {
  .container {
    .content {
      // display: flex;
      // max-width: 1000px;
    }
    .activity-table {
      position: relative;
      
      margin-top: 100px;
      display: flex;
      &-title-container {
        align-self: flex-start;
        width: 100%;
        max-width: 1016px;
        margin: 0 auto;
        .activity-table-title, .activity-table-subtitle {
          margin-left: 4rem;
        }
      }
      &-title {
        font-size: 3rem;
        font-family: 'Comfortaa';
        color: $violet2;
        margin-bottom: 19px;
        display: none;
        @media (min-width: $bp-sw) {
          display: inherit;
        }
      }
      &-subtitle {
        max-width: 419px;
        font-size: 1.125rem;
        color: $grayscale4;
        line-height: 28px;
        @media (max-width: $bp-sw) {
          margin: 0 33px 76px 33px;
        }
        margin-bottom: 76px;
      }
      .transfer {
        display: flex;
        max-width: 1016px;
        flex-direction: row;
        gap: 12px;
        width: 100%;
        justify-content: center;
        .dashed-line {
          content:"";
          position: relative;
          z-index: 2;
          top: 55px;
          left: 32px;
          border-left: 2px dashed #D5DCF3;
          justify-content: flex-start;
          opacity: 0.5;
        }
        .round-box {
          &:hover {
            text-decoration: none;
          }
          &.first {
            width: 100%;
          }
          width: 92%;
          height: 74px;
          gap: 16px;
          &-image {
            img {
              width: 50px;
              min-width: 50px;
              height: 50px;
            }
          }
          &-content {
            color: $grayscale5;
            .pill {
              margin-bottom: 4px;
              &.Claim, &.Transfer, &.Migration, &.Burn {
                span {
                  height: 0.65rem;
                }
                font-size: 0.75rem;
                border-radius: 100px!important;
                padding: 6px 12px!important;
              }
              &.Claim {
                * {
                  color: #0FCEAD!important;
                }
                background-color: #DCFFF9!important;
              }
              &.Transfer {
                * {
                  color: $violet2!important;
                }
                background-color: #EEEDFF!important;
              }
              &.Migration {
                * {
                  color: #3FADF6!important;
                }
                background-color: #E7F5FF!important;
              }
              &.Burn {
                * {
                  color: $pink1!important;
                }
                background-color: $pink6!important;
              }
            }
            & > span {
              font-size: 1rem;
            }
          }
          &-time {
            margin-left: auto;
            color: $grayscale4;
            font-size: 0.75rem;
          }
        }
      }
    }
  
    .gallery-title {
      max-width: 1120px;
      margin: 0 auto;
      margin-top: 48px;
      margin-bottom: 8px;
      font-size: 1.375rem;
      font-family: 'Comfortaa';
      font-weight: 500;
      color: $grayscale5;
    }
    .gallery-grid {
      display: grid;
      grid-gap: 1.5rem!important;
      grid-auto-flow: row;
      grid-auto-columns: 262px;
      grid-template-rows: 90px auto;
      justify-content: center;
      max-width: 1120px;
      margin: 0 auto;
      margin-top: 8px;

      .gallery-card {
        place-self: center;
      }
    }
  }
}

.gallery-search {
  position: relative;
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  place-self: end stretch;
  margin: .5rem 0;
  align-self: flex-start;
}

.gallery-search input {
  margin-bottom: 0;
}

.gallery-filter {
  grid-column: 1 / 3;
  place-self: end;
  width: 100%;
  align-self: flex-start;
}

.failed-search {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  grid-column: 1 / 3;
  height: 200px;
  margin-top: 38px;
  * {
    font-family: 'Comfortaa';
    font-weight: 500;
    font-size: 1.375rem;
  }
  font-size: 1.375rem;
}

@media (min-width: $bp-s) {
  .gallery-grid.activity-grid {
    grid-template-columns: repeat(1, 295px);
    place-items: center;
  }
}

@media (max-width: 590px) {
  .gallery-grid {
    margin-top: 8px;
    // grid-template-columns: repeat(1, 1fr)!important;
    .gallery-search {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    
    .gallery-filter {
      grid-column: 1 / 2;
    }
    
    .failed-search {
      grid-column: 1 / 2;
    }
    .spinner {
      grid-column: 1 / 2;
    }
  }
}

@media (min-width: $bp-m){ // 479 px
 
  .gallery-grid {
    
    // grid-template-columns: repeat(2, 1fr);
    .gallery-search {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    
    .gallery-filter {
      grid-column: 2 / 3;
    }
    .spinner {
      grid-column: 1 / 3;
    }
    .failed-search {
      grid-column: 1 / 3;
    }
  }
  .gallery-grid.activity-grid {
    grid-template-columns: repeat(2, 295px);
    place-items: center;
  }
}

@media (min-width: 950px){
 
  .gallery-grid {
    
    // grid-template-columns: repeat(3, 1fr);
    .gallery-search {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    
    .gallery-filter {
      grid-column: 2 / 4;
    }
    .spinner {
      grid-column: 1 / 4;
    }
    .failed-search {
      grid-column: 1 / 4;
    }
  }
  .gallery-grid.activity-grid {
    grid-template-columns: repeat(2, 295px);
    place-items: center;
  }
}

@media (min-width: $bp-l){ //1140 px
  .gallery-grid{
    // grid-template-columns: repeat(4, 1fr);
    .gallery-search {
      grid-column: 1 / 2;
    }
    
    .gallery-filter {
      grid-column: 3 / 5;
    }

    .spinner {
      grid-column: 1 / 5;
    }
    .failed-search {
      grid-column: 1 / 5;
    }
  }
  .gallery-grid.activity-grid {
    grid-template-columns: repeat(3, 295px);
    place-items: center;
  }

  .gallery-grid.no-show-one {
    grid-template-columns: repeat(2, 295px);
    place-items: center;
  }
}

.gallery-grid.activity-grid {
  gap: 2rem;
}

@media (max-width: $bp-m){ // 479 px
  .activitycards{
    flex-direction: column; 
  }
}

.poap-app {
  header {
    height: 70px;
    box-shadow: 0px 3px 6px 0px #BBC4EF5B;

    -webkit-overflow-scrolling: touch;
    -webkit-transition: all 0.5s ease-in-out-ut;
    transition: all 0.5s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 100;
    top: -200px;
    position: static;
    width: 100%;
    padding: 0;

    &.fixed {
      width: 100%;
      z-index: 100;

      position: fixed;
      top: 0;
      -webkit-overflow-scrolling: touch;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
    }

    .container {
      max-width: none;
      width: auto;
      padding: 0;
      display: flex;
      flex-direction: row;

      margin: 0;
      @media (min-width: $bp-sw) {
        margin: 0 58px;
      }

      height: inherit;
      & > div {
        height: inherit;
      }
      .pull-left {
        .logo {
          width: 63px;
          height: 82.74px;
          margin-right: 16px;
        }
        span {
          display: flex;
          align-items: center;
          padding-left: 14px;
          margin: 29px 0 21px 0;
          border-left: 1px solid $grayscale2;
          color: $grayscale4;
          font-family: 'Comfortaa';
          font-weight: $fontWeight-normal;
          font-size: 1.125rem;
        }
      }
    }

    .logo {
      margin: 10px 0 0 10px;
      float: left;
    }

    .link {
      font-size: 1rem;
      font-weight: $fontWeight-normal;
      color: $color-primary;
      font-family: $font-family;
      display: flex;
      align-items: center;
      height: 100%;

      @media (max-width: $bp-m) {
        font-size: 0.5rem;
      }
    }
  }
}

/* ******************************************************
   App Content
   ****************************************************** */

.app-content {
  .content-event {
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    display: block;

    p {
      span {
        font-weight: $fontWeight-bold;
      }
    }
    .login-form {
      text-align: center;

      input[type='text'] {
        max-width: 500px;
        text-align: center;
      }
    }

    &.years {
      text-align: left;

      @media (min-width: $bp-mw) {
        margin: 0 auto 50px;
        max-width: 600px;
      }

      h1 {
        font-weight: $fontWeight-normal;
        @include x-rem(font-size, 20px);
        margin: 0 0 12px;

        span {
          font-weight: $fontWeight-bold;
          @include x-rem(font-size, 16px);

          @media (min-width: $bp-m) {
            @include x-rem(font-size, 20px);
          }
        }
      }

      p {
        max-width: none;
      }

      .event-year {
        margin-bottom: 30px;

        &:last-child {
          margin: 0;
        }

        h2 {
          color: $color-fourth;
          @include x-rem(font-size, 30px);
          font-family: $font-family-narrow;
          margin-bottom: 12px;
        }

        &.empty-year {
          img {
            width: 100%;
            max-width: 320px;
            margin: 0 auto 20px;
            display: block;
          }

          .image-description {
            width: 100%;
            margin: 0 auto;
            max-width: 320px;
            text-align: center;
            color: #b29ffa;

            @media (min-width: $bp-mw) {
              max-width: 360px;
              @include x-rem(font-size, 20px);
            }
          }
        }

        .events-logos {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .event-circle {
            width: 95px;
            height: 95px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 12px 12px 0;
            background-color: $color-primary;
            -webkit-box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);
            -moz-box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);
            box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);

            @media (min-width: $bp-mw) {
              margin: 0 24px 24px 0;
            }
          }
        }
      }
    }

    &.contact-form {
      text-align: left;

      @media (min-width: $bp-m) {
        margin: 0 auto 50px;
        max-width: 600px;
      }

      h1 {
        font-weight: $fontWeight-normal;
        @include x-rem(font-size, 20px);
        margin: 0 0 12px;
      }

      form {
        margin: 24px 0 0;

        input[type='text'],
        select,
        input[type='submit'] {
          max-width: none;
        }

        .form-group {
          margin: 0 0 24px;
          float: left;
          width: 100%;
        }
      }
    }
  }
}

.event-main {
  margin-top: 39px;

  .container {
    .prev-next-buttons {
      a {
        display: flex;
        align-items: flex-end;
        svg {
          margin: 0 11px;
        }
      }
      h4 {
        text-align: center;

        .event-title {
          font-size: 0.75rem;
          font-weight: $fontWeight-normal;
          color: $grayscale4;
        }
        .event-id {
          margin-top: 8px;
          font-weight: 500;
          font-family: 'Comfortaa';
          color: $grayscale5;
          font-size: 1.25rem;
        }
      }
    }
    @media (max-width: $bp-sw) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .table-title {
    font-size: 1.25rem;
    color: $grayscale5;
    font-weight: 500;
    font-family: 'Comfortaa';
    span {
      font-size: 1rem;
      color: $grayscale4;
      font-weight: $fontWeight-normal;
      font-family: 'Noway Round';
    }
  }
  .mobile-row {
    transition: max-height 0.4s linear;
    max-height: 78px;
    overflow: hidden;
    &.open {
      max-height: 200px;
    }
    display: grid;
    grid-template-columns: 1.5fr 1.1fr 0.9fr;
    gap: 1rem;
    padding: 12px 15px;
    text-align: left;
    grid-template-areas: 
    'id-title id-content id-content'
    'address-title address-content address-content'
    'claim-title claim-content claim-content'
    'tr-count-title tr-count-content tr-count-content'
    'power-title power-content power-content';

    .power-content, .tr-count-content, .claim-content, .id-content, .address-content {
      text-align: right;
    }

    color: $grayscale4;
    [class*='title'] {
      word-break: normal;
      color: $grayscale6;
    }
    .id-title {grid-area: id-title;}
    .id-content {grid-area: id-content;}
    .address-title {grid-area: address-title;}
    .address-content {grid-area: address-content;}
    .claim-title {grid-area: claim-title;}
    .claim-content {grid-area: claim-content;}
    .tr-count-title {grid-area: tr-count-title;}
    .tr-count-content {grid-area: tr-count-content;}
    .power-title {grid-area: power-title;}
    .power-content {grid-area: power-content;}
    .expand-button {grid-area: expand-button; text-align: end;}
  }
}

.activity-main {
  margin-top: 62px;
  transition: all 0.2s ease-out;

  .mobile-row {
    overflow: hidden;
  }
  .recent-activity {
    display: flex;
    justify-content: flex-start;
    align-items: center;


    & > img {
      display: none;
    }
    &-content {
      display: grid;
      grid-template-rows: 21px 19px;
      grid-template-columns: auto 1fr;
      gap: 6px;
      grid-template-areas: 
        'activity-type-pill  time'
        'description description'
        ;

      .activity-type-pill {
        grid-area: activity-type-pill;
        align-items: flex-end;

        .Claim, .Transfer, .Migration, .Burn {
          font-size: 0.75rem;
          border-radius: 100px!important;
          padding: 6px 12px!important;
          height: 21px;
        }
        .Claim {
          * {
            color: #0FCEAD!important;
          }
          background-color: #DCFFF9!important;
        }
        .Transfer {
          * {
            color: $violet2!important;
          }
          background-color: #EEEDFF!important;
        }
        .Migration {
          * {
            color: #3FADF6!important;
          }
          background-color: #E7F5FF!important;
        }
        .Burn {
          * {
            color: $pink1!important;
          }
          background-color: $pink6!important;
        }
      }
      .time {
        height: 0.75rem;
        font-size: 0.75rem;
        grid-area: time;
        text-align: start;
        align-items: flex-start;
        align-self: center;
        color: $grayscale4;
      }
      .description {
        font-size: 1rem;
        grid-area: description;
        text-align: start;
        word-break: break-word;
        a {
          text-decoration: none;
          color: $violet2;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    .expand-button {
      height: 50px;
      display: flex;
      justify-content: flex-end;
    }
    & > a > img {
      display: inherit;
    }
    &-content .time {
      display: flex;
    }
    @media (min-width: $bp-l) {
      & > img {
        display: inherit;
      }
    }
  }
  .mobile-row-content {
    display: none;
    &.open {
      display: grid;
    }
    grid-template-columns: 1fr 1.5fr;
    gap: 1rem;
    padding: 20px 15px 0 15px;
    text-align: left;
    grid-template-areas: 
      'id-title id-content'
      'address-title address-content'
      'tr-count-title tr-count-content'
      'claim-title claim-content';

    color: $grayscale4;
    [class*='title'] {
      word-break: normal;
      color: $grayscale6;
    }
    .id-title {grid-area: id-title;}
    .id-content {grid-area: id-content;}
    .address-title {grid-area: address-title;}
    .address-content {grid-area: address-content;}
    .claim-title {grid-area: claim-title;}
    .claim-content {grid-area: claim-content;}
    .tr-count-title {grid-area: tr-count-title;}
    .tr-count-content {grid-area: tr-count-content;}
    .power-title {grid-area: power-title;}
    .power-content {grid-area: power-content;}
    .expand-button {grid-area: expand-button; text-align: end;}
  }
}

.loading-content {
  width: 100%;
  text-align: center;
}

.expand-button {
  font-size: 1.1rem;
  * {
    color: $violet2;
  }
}

.event-main {
  .container {
    .table-header {
      display: flex;
      align-items: flex-end;
      overflow: visible;

      margin: 0 20px;
      @media (min-width: $bp-m) {
        margin: 0 58px;
      }
      @media (min-width: $bp-mw) {
        margin: 0 98px;
      }
    }
  }
}

.table-header, .table-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  
  margin: 0 20px;
  max-width: 1224px;
  @media (min-width: $bp-mw) {
    margin: 0 70px;
  }
  @media (min-width: 1224px) {
    margin-left: auto!important;
    margin-right: auto!important;
  }
}

.table-container {
  .event-table {
    margin-top: 1em;
  
    .pagination {
      height: 10px;
    }
  }
}


table {
  border: 1px solid $grayscale1;
  border-radius: 20px;
  overflow: hidden;
  border-spacing: 0;
  empty-cells: show;

  thead {
    tr {
      height: 42px;
      th {
        background: rgb(240,244,254);
        color: $grayscale6;
        font-size: 0.875rem;
        font-weight: $fontWeight-normal;
      }
    }
  }
  tbody {
    tr {
      text-align: center;
      * {
        color: $grayscale6;
      }
      a:hover {
        color: $violet2;
        text-decoration: none;
      }
      span:hover {
        text-decoration: none;
      }
      td {
        border: none;
        padding-left: 5px;
        padding-right: 5px;
        * {
          word-break: break-all;
        }
      }
      &:last-child {
        height: 20px;
        background: $grayscale1;
      }
    }
  }
  tr {
    height: 67px;
    &:nth-child(even) td {
      background: rgb(248,250,255); // equivalent to rgba($grayscale1, 0.3)
    }
    &:nth-child(odd) td {
      background: $white;
    }
  }
}

.activity-table-container {
  .recent-activity {
    &-image {
      flex: 0 0 118px;
    }
  }
  tbody td {
    padding: 14px;
  }
}

.pill {
  background-color: $grayscale0;
  color: $grayscale5;
  border-radius: 8px;
  width: fit-content;
  height: 1.8rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: .875rem;
  padding: .35rem;
  display: flex;
  align-items: center;
}

.hidden {
  display: none;
}

.circle-container {
  object-fit: cover;
  border-radius: 50%;
}

.round-box {
  box-shadow: 0 4px 16px 0 #BBC4EF59;
  transition: box-shadow .2s ease;
  &:hover {
    box-shadow: 0 6px 20px 3px #BBC4EF73;
  }
  &:focus, &:active {
    text-decoration: none;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  background: $white;
  overflow: hidden;
  padding: 1rem;
}

.ReactModal__Overlay--after-open {
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReactModal__Content--after-open {
  top: 0!important;
  display: flex;
  justify-content: center;
  img {
    max-width: 80%;
  }
}

.external-link {
  position: absolute;
  display: flex;
  width: 24px;
  height: 24px;
  right: -37px;
  top: -7px;
  border-radius: 50%;
  justify-content: center;
  &:hover {
    background-color: #EEEDFF;
  }
  img {
    width: 12px;
  }
}

div.center {
  display: flex;
  justify-content: center;
}