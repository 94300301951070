.select {
  width: 100%;
  box-shadow: none;
  border: 2px solid $grayscale2;
  background: $color-invert;
  color: $grayscale5;
  transition: right 0.2s ease-out;
  border-radius: 4px;
  margin: 0 0 24px;
  @include x-rem(font-size, 16px);
  height: 40px;

  appearance: none;

  outline-color: $color-primary;
  cursor: pointer;

  &:last-child {
    margin: 0;
  }

  &:hover,
  &:focus {
    border: 1px solid $color-primary;
  }

  &:disabled {
    background-color: $color-light-violet;
    color: $color-primary;

    &:hover {
      border: none;
    }
  }

  &.placeholder-option {
    color: $color-medium-violet;
  }

  &-button {
    padding: 12px;
  }
  &-options {
    position: relative;
    z-index: 1;
    background-color: inherit;
    box-shadow: 0 4px 16px 0 #BBC4EF59;
    border-radius: 6px;
    .option {
      width: 100%;
      padding: 1rem;
      color: $grayscale4;

      &:hover {
        background-color: #EEEDFF;
        color: $violet2;
      }
    }
  }
}