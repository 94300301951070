/* ******************************************************
   Main Sections Events App
   ****************************************************** */

.eventsapp {
  background-color: $color-secondary;
  float: left;
  width: 100%;

  &.green {
    background-color: $color-third;
  }
}

/* ******************************************************
   Header
   ****************************************************** */

.header-events {
  text-align: center;
  padding: 70px 0 0;
  background-color: $bg-color;

  @media (min-width: $bp-mw) {
    padding: 70px 0 100px;
  }

  h1 {
    margin-bottom: 40px;
  }

  .logo-event {
    position: relative;
    z-index: 1;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: $color-primary;
    margin: 0 auto;
    -webkit-box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);
    -moz-box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);
    box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);
  }

  &.wallet {
    h1 {
      margin: 0 0 15px 0;
    }
    .logo-event {
      width: 160px;
      height: 160px;
    }
  }
}

/* ******************************************************
   Main
   ****************************************************** */

.main-events {
  margin: -100px 0 0;

  @media (min-width: $bp-m) {
    margin: -115px 0 0;
  }

  @media (min-width: $bp-mw) {
    margin: -200px 0 0;
  }

  .image-main {
    float: left;
    overflow: hidden;
    width: 100%;

    img {
      width: 102%;
      margin: 0 0 0 -1%;
      max-width: none;
    }
  }

  .main-content {
    background: $color-secondary;
    margin: -1px auto 0;
    padding: 100px 0 0;
    float: left;
    width: 100%;

    @media (min-width: $bp-m) {
      padding: 50px 0 0;
    }

    @media (min-width: $bp-l) {
      padding: 0;
    }

    .content-event {
      max-width: 6600px;
      margin: 0 auto;
      text-align: center;

      p,
      h1,
      h2,
      h3 {
        color: $color-invert;
      }

      p {
        @include x-rem(font-size, 16px);
        margin-bottom: 12px;
      }

      h2 {
        margin-bottom: 5px;
        @include x-rem(font-size, 30px);
      }

      .wallet-number {
        @include x-rem(font-size, 13px);
        font-weight: $fontWeight-bold;
        color: $color-primary;
        margin-bottom: 20px;
        max-width: none;

        @media (min-width: $bp-m) {
          @include x-rem(font-size, 19px);
        }
      }

      h3 {
        margin-bottom: 20px;
        @include x-rem(font-size, 40px);
      }

      .error {
        color: $error-red;
        max-width: 400px;
        margin: 0 auto;
        @include x-rem(font-size, 20px);
        text-align: center;
      }
    }
  }

  &.green {
    .main-content {
      background-color: $color-third;
      padding: 70px 0 0;

      @media (min-width: $bp-mw) {
        padding: 0 0 0;
      }
    }
  }
}

/* ******************************************************
   Effects
   ****************************************************** */

.highlight-effect {
  position: relative;
  z-index: 1;
}

.highlight-effect::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: -0.05em;
  right: -0.05em;
  background-color: #fe2d4d;
  transform-origin: center right;
  transform: scaleX(0);
  transition: transform 0.2s ease-in-out;
}

.highlight-effect:hover::before {
  transform: scaleX(1);
  transform-origin: center left;
}

/* ******************************************************
  Minting Page
  ****************************************************** */
.code-claim-page {
  background: $color-invert;
  min-height: 100vh;
  .claim-header {
    position: relative;
    background: $bg-color;

    .title {
      text-align: center;
      font-size: 38px;
      color: $color-primary;
      font-weight: bold;
      padding: 80px 0 20px;
      font-family: $font-family-narrow;
    }

    .logo-event {
      position: relative;
      z-index: 1;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background-color: white;
      margin: 0 auto;
      -webkit-box-shadow: 0 4px 14px 0 rgba($color-primary, 0.5);
      -moz-box-shadow: 0 4px 14px 0 rgba($color-primary, 0.5);
      box-shadow: 0 4px 14px 0 rgba($color-primary, 0.5);

      .image-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
      }

      @media (min-width: $bp-mw) {
        width: 200px;
        height: 200px;
      }

      .claimed-badge {
        background: $color-fourth;
        -webkit-box-shadow: 0 4px 14px 0 rgba($color-primary, 0.5);
        -moz-box-shadow: 0 4px 14px 0 rgba($color-primary, 0.5);
        box-shadow: 0 4px 14px 0 rgba($color-primary, 0.5);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: absolute;
        text-align: center;
        top: 90px;
        right: 10px;

        @media (min-width: $bp-mw) {
          top: 160px;
          right: 20px;
          width: 50px;
          height: 50px;
        }

        img {
          padding-top: 4px;
          width: 20px;
          @media (min-width: $bp-mw) {
            width: 40px;
            right: 30px;
          }
        }
      }
    }

    .wave-holder {
      width: 100%;
      margin-top: -60px;

      @media (min-width: $bp-mw) {
        margin-top: -100px;
      }

      img {
        width: 100%;
      }
    }
  }

  .claim-body {
    padding-top: 50px;
    @media (min-width: $bp-mw) {
      padding-top: 0;
    }
    @media (min-width: $bp-xxl) {
      margin-top: -50px;
    }

    .claim-form {
      text-align: center;

      input {
        font-size: 16px;
        text-align: center;
        border: 1px solid $color-primary;
        max-width: 500px;
      }

      .web3-browser {
        font-size: 16px;
        padding: 25px 0;
        font-family: $font-family-narrow;
        color: $color-medium-violet;
        span {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }

    .claim-info {
      padding: 20px;
      max-width: 600px;
      text-align: center;
      margin: auto;

      .claim-preloader {
        padding: 50px 0;
        text-align: center;

        img {
          width: 50px;
        }
      }

      .info-title {
        font-family: $font-family-narrow;
        font-weight: bold;
        color: $color-primary;
        font-size: 26px;
        line-height: 30px;
        max-width: 300px;
        margin: 30px auto;
      }

      .info-pending {
        background: $color-light-grey;
        color: $color-medium-violet;
        display: inline;
        border-radius: 4px;
        padding: 10px 10px 10px 35px;
        font-size: 16px;
        line-height: 20px;
        position: relative;

        img {
          width: 25px;
          position: absolute;
          left: 5px;
          top: 15px;
        }
      }

      .text-info {
        color: $color-medium-violet;
        font-size: 16px;
        line-height: 20px;
        max-width: 300px;
        margin: 40px auto;
      }

      a.link-btn {
        margin-top: 50px;
        background: $color-fourth;
        width: 300px;
        min-width: 300px;
      }
    }
  }

  .claim-footer {
    padding: 10px 0 0;
    text-align: center;
    font-size: 1.1rem;
    line-height: 1.5rem;
  }

  footer {
    background: #fff;
    padding: 0;
  }
}
