/* ================================================================
   Boilerplate - Base Styles
   ================================================================ */

html,
button,
input,
select,
textarea {
  color: $color-base;
}

body {
  font-family: $font-family;
  background: $color-secondary;
  font-size: 16px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: $grayscale3 transparent;
  
  &::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
    margin: 6px;

    &-track {
      background: transparent;        /* color of the tracking area */
    }

    &-thumb {
      background-color: $grayscale3;    /* color of the scroll thumb */
      border-radius: 20px;       /* roundness of the scroll thumb */
      border: 1px solid white;
    }
  }
}

header,
main {
  float: left;
  width: 100%;
  clear: both;
}

#root .landing {
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.landing > .app-content {
  min-height: calc(100vh - 141px - 406.25px)
}

::-moz-selection {
  background: $color-primary;
  color: $color-invert;
  text-shadow: none;
}

::selection {
  background: $color-primary;
  color: $color-invert;
  text-shadow: none;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

img {
  vertical-align: middle;
  width: auto;
  height: auto;
  max-width: 100%;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

/* ================================================================
   Typography Styles
   ================================================================ */

h1,
h2,
h3,
h4,
h5 {
  color: $color-primary;
  font-weight: $fontWeight-bold;
  margin: 0 0 $typography-margin-bottom;
  font-family: $font-family;
}

h1 {
  @include x-rem(font-size, 40px);
}

h2 {
  @include x-rem(font-size, 20px);

  @media (min-width: $bp-mw) {
    @include x-rem(font-size, 35px);
  }
}

h3 {
  @include x-rem(font-size, 24px);

  @media (min-width: $bp-m) {
    @include x-rem(font-size, 30px);
  }
}

a {
  color: $color-primary;
  text-decoration: none;
  font-family: $font-family;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

p {
  margin: 0 auto $typography-margin-bottom;
  @include x-rem(font-size, 16px);
  color: $color-base;
  font-family: $font-family;
  max-width: 350px;

  &:last-child {
    margin-bottom: 0;
  }
}

ul,
ol {
  margin: 0 0 20px;
  padding: 0 0 0 30px;

  li {
    @include x-rem(font-size, 16px);
    color: $color-invert;
    font-family: $font-family;
    margin: 0 0 10px;
  }
}

.btn {
  display: block;
  min-width: 20%;
  margin: 1rem 0px;
  cursor: pointer;

  margin-top: 20px; 
  padding: 12px 24px;

  border-radius: 100px;

  font-weight: $fontWeight-normal;
  font-family: $font-family;
  font-size: 1rem;
  border: 0;
  text-align: center;
  color: $color-invert;
  text-decoration: none;

  background: $violet1;
  transition: all 0.2s ease-out;

  -webkit-box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);
  -moz-box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);
  box-shadow: 0px 10px 30px -5px rgba($color-primary, 0.5);

  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: none;
    background: #683dee;
  }

  &:active {
    text-decoration: none;
    background: #6534ff;
  }
  &:disabled {
    text-decoration: none;
    background: #ccc4f7;
    color: #e6e7f7;
  }

  span {
    float: left;
    width: 100%;
    margin: 0 0 3px 0;

    &:last-child {
      margin: 0;
    }
  }
  .small-text {
    @include x-rem(font-size, 16px);
  }
}

.mobile {
  @media (min-width: $bp-m) {
    display: none;
  }
}

.desktop {
  display: none;
  @media (min-width: $bp-m) {
    display: block;
  }
}

.capitalize {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.tdColumnImage{
  height: 26px;
}

.button-disabled {
  cursor: default;
  background-color: lightgray;
  &:hover, &:focus-visible, &:focus {
    background-color: lightgray;
  }
  box-shadow: none!important;
}

.token-not-found {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  text-align: center;
}

.discreet-paragraph {
  margin: 0;
  color: $grayscale5;
  word-break: break-word;
  margin-bottom: 0.8em;
}

.no-margin {
  margin: 0;
}