/* ================================================================
   Variables
   ================================================================ */

// Color Variables
$violet1: #9289FF; // backgrounds
$violet2: #8076FA; // text
$violet3: #7168DE;
$violet4: #B1AFFF;
$violet5: #DDDBFF;

$grayscale6: #4D5680;
$grayscale5: #6873A4;
$grayscale4: #8492CE;
$grayscale3: #C4CAE8;
$grayscale2: #D5DCF3;
$grayscale1: #E6EDFE;
$grayscale0: #F1F5FD;

$success-light: #40DD7F;
$error-red: #FB4E4E;
$pink1: #F9879A;
$pink2: #F87588;
$pink5: #FCCDD3;
$pink6: #FFEDF0;
$white: #FFF;


$color-base: $grayscale6;
$color-invert: $white;
$color-disabled-field: $grayscale1;
$bg-color: $white;
$color-primary: $violet2;
$color-secondary: $violet5;
$a: $grayscale1;
$color-third: $success-light;
$color-fourth: $pink2;

$color-medium-violet: $grayscale4;
$color-light-violet: #cabdf3;
$color-light-grey: #eef0fb;
$color-grey: #878787;
$color-dark: #222;


$gray-select-arrow: linear-gradient(45deg, transparent 50%, $grayscale4 50%),
  linear-gradient(135deg, $grayscale4 50%, transparent 50%);

$red-select-arrow: linear-gradient(45deg, transparent 50%, $error-red 50%),
  linear-gradient(135deg, $error-red 50%, transparent 50%);

// Typography
$font-family: 'Noway Round', 'Archivo', sans-serif;
$font-family-narrow: 'Archivo Narrow', sans-serif;

$fontWeight-light: 300;
$fontWeight-normal: 400;
$fontWeight-medium: 500;
$fontWeight-semibold: 600;
$fontWeight-bold: 700;
$typography-margin-bottom: 1em;

// Breakpoints
// You can convert these at http://pxtoem.com
$bp-s: 20em; // 320px
$bp-s-max: 29.938em; // 479px
$bp-sw: 30em; // 480px
$bp-sw-max: 47.938em; // 767px
$bp-m: 48em; // 768px
$bp-m-max: 59.938em; // 959px
$bp-mw: 60em; // 960px
$bp-mw-max: 71.188em; // 1139px
$bp-l: 71.25em; // 1140px
$bp-l-max: 81.188em; // 1299px
$bp-xl: 81.25em; // 1300px
$bp-xxl: 91.25em; // 1400px

// Grid
$grid-gutter: 16px;
$grid-gutter-em: 1em; // Changing grid gutter to em causes a compiling error :/
